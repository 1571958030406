// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-hbr-js": () => import("./../../../src/pages/case-study/hbr.js" /* webpackChunkName: "component---src-pages-case-study-hbr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pattern-library-js": () => import("./../../../src/pages/pattern-library.js" /* webpackChunkName: "component---src-pages-pattern-library-js" */)
}

